<template>
    <v-main>
        <app-menu/>
        <v-toolbar>
            <v-tabs v-model="tab"
            
            next-icon="mdi-arrow-right-bold-box-outline"
            prev-icon="mdi-arrow-left-bold-box-outline"
            show-arrows>
                <v-tabs-slider color="yellow"></v-tabs-slider>
                <v-tabs v-model="tab" background-color="primary" grow dark>
                    <v-tab>Comentarios de clientes</v-tab>
                </v-tabs>
            </v-tabs>
        </v-toolbar>
        <v-card min-height="100%">
            <Comentarios v-if="tab == 0"/>
        </v-card>
    </v-main>
</template>
<script>
import AppMenu from "../../components/admin/MenuAdmin.vue";
import Comentarios from "../../components/admin/TablaComentarios.vue"
export default {
    data(){
        return{
            tab:"",

        }
    },
    components:{
     "app-menu": AppMenu,
        Comentarios 
    }
    
}
</script>
<template>
    <v-container>
        <v-card >
            <v-card-title>
                <v-text-field color="cyan" v-model="search" label="Buscar comentarios" prepend-icon="mdi-notebook-edit-outline"></v-text-field>
            </v-card-title>
            <v-data-table
            no-data-text="No hay comentarios disponibles"
            no-results-text="No hay coincidencias disponibles"
            :footer-props="{'items-per-page-options':[10,20,30,40]}"
            :headers="headers" :items="comentarios" :search="search"
            >
            <template v-slot:[`item.mensaje`] ="{ item }">
                <center>
                    <v-card dark width="100%" max-width="600" elevation="0">
                          <div class="text-left container" style=" border: 0px  #208DEE; border-radius:5px; box-shadow:10px 5px 10px 0px #208DEE;">
                            {{item.mensaje}}
                          </div>
                    </v-card>
                </center>
            </template>
            <template v-slot:[`item.nombre_cliente`]= "{ item }">
                <center>
                    <v-row>
                        <v-col md="2">
                                <v-img class="imagen" style="" width="30px" src="../../../public/usuario.png"></v-img>
                            <!-- <v-card width="50" elevation="24">
                                <v-icon style="font-size:48px;color:gray;">mdi-account-circle</v-icon> -->
                            <!-- </v-card> -->
                        </v-col>
                        <v-col md="10">
                            {{item.nombre_cliente}}
                        </v-col>
                    </v-row>
                    
                    
                </center>
            </template>
            <template v-slot:[`item.documentno`]="{ item }">
                <center style="color:black;">
                    <strong>
                        {{item.documentno}}
                    </strong> 
                </center>
            </template>
            <template v-slot:[`item.fechacomentario`]="{ item }">
                <center>
                    {{formatDate(item.fechacomentario)}}
                </center>
            </template>
            
            </v-data-table>

        </v-card>
        
    </v-container>
</template>
<style>
    .v-card .v-data-table-header th[role=columnheader] {
        font-size: 13px !important;
        text-align: center !important;
    }
    .imagen:hover {
        filter: saturate(280%);
    }
</style>
<script>
import axios from 'axios';
import config from '../../json/config.json'
export default {
    data: () => ({
        search:"",  
        comentarios:[],
        headers:[
            {text:"No. Orden", value:"documentno"},
            {text:"Nombre del cliente", value:"nombre_cliente"}, 
            {text:"Comentario",value:"mensaje"},
            {text:"Fecha",value:"fechacomentario"}
        ],
    }),
    methods:{
        async mostrarComentarios(){
            await axios.get(config.apiAmoresens + '/comentario/obtenerComentarios', 
            {headers:{token: this.$cookie.get('token')}})
            .then(res=>{
                this.comentarios = res.data.data;
                // console.log(this.comentarios);
            }).catch(err=>{
                console.log(err);    
            })
        },
        async validaLogin(){
          this.user = await axios.get(config.apiAmoresens + "/user/userByTokenAdmin", 
          {
            'headers': { 'token': this.$cookie.get('token') }
          }).then(res=>{return res.data;})
          .catch(err=>{return err;});
          if (this.user.status == "success") {
            this.user = this.user.user;
            this.isLogged = true; 
          }else if(this.user.status == "unauthorized"){ 
            this.isLogged = false;
          } 
        },
        formatDate(dates) {
            // const d = new Date(dates) ;
            // return `${d.getDay(dates)} de ${d.getMonth(dates)} de ${d.getFullYear()}`
            const dias = ['Lunes','Martes','Miércoles','Jueves','Viernes','Sábado','Domingo'];
            var month= ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio",
                "Agosto","Septiembre","Octubre","Noviembre","Diciembre"];  
            return `${dias[(new Date(dates)).getDay()-1]} ${(new Date(dates)).getDate()} de ${month[(new Date(Date.parse(dates))).getMonth()]} de ${(new Date(Date.parse(dates))).getFullYear()}`
        },
    },
    created(){
        this.mostrarComentarios();
        this.validaLogin();
    }
}
</script>